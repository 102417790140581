<template>
  <div class="box">
    <div class="top">
      <div class="title">录入人员信息</div>
    </div>
    <div class="innerBox">
      <el-form ref="form" :model="form"  :rules="rules" label-width="80px">
        <el-form-item label="姓名"  prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <!-- <el-select v-model="form.gender" placeholder="">
            <el-option label="女" value="女"></el-option>
            <el-option label="男" value="男"></el-option>
          </el-select> -->
          <el-radio-group v-model="form.gender">
            <el-radio label="女">女</el-radio>
            <el-radio label="男">男</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="编号" prop="id">
          <el-input v-model="form.id"></el-input>
        </el-form-item>
        <el-form-item label="体检单位" prop="dw">
          <el-input v-model="form.dw"></el-input>
        </el-form-item>
        <el-form-item label="类别" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio label="食品">食品</el-radio>
            <el-radio label="公共卫生">公共卫生</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="时间" prop="date">
          <!-- <el-col :span="11"> -->
            <el-date-picker type="date"
              placeholder="选择日期"
              v-model="form.date"
              style="width: 100%;"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          <!-- </el-col> -->
          <!-- <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-time-picker placeholder="选择时间" v-model="form.date" style="width: 100%;"></el-time-picker>
          </el-col> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">立即创建</el-button>
        </el-form-item>
      </el-form>

    </div>

    <!-- <div class="selectBox">
      <div v-for="(item,index) in list2" :key="index" class="select">
        <div class="selectText">
          <div>{{ index+1 }}、</div>
          <div>故障现象</div>
          <div>{{ index+1 }}</div>
        </div>
        <el-select v-model="item.content" filterable multiple placeholder="请选择">
        <el-option 
          v-for="item in options"
          :key="item.label"
          :label="item.label"
          :value="item.label">
        </el-option>
      </el-select>
      </div>
    </div> -->
  </div>
</template>

<script>
import { submit } from '@/api/index.js'
export default {
  components: {},
  props: {},
  data() {
    return {
      form: {
        name: '',
        gender: '',
        id: '',
        dw: '',
        type: '',
        date: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        dw: [
          { required: true, message: '请输入单位', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择一个类别', trigger: 'change' }
        ],
        date: [
          { required: true, message: '请选择时间', trigger: 'blur' }
        ],
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          console.log('form', this.form)
          let obj = JSON.stringify({
            category: this.form.type,
            // code: string,
            dataTime: this.form.date,
            name: this.form.name,
            number: this.form.id,
            sex: this.form.gender,
            unit: this.form.dw,
          })
          submit(obj).then(res => {
            console.log('sub', res)
            this.$notify({
              title: '添加成功',
              message: `成功添加${this.form.name}信息`,
              type: 'success'
            });
          })
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    // put() {
    //   // sessionStorage.setItem('list1', JSON.stringify(this.list1))
    //   // sessionStorage.setItem('list2', JSON.stringify(this.list2))
    //   let list = [...this.list1, ...this.list2]
    //   console.log('list', list)
    //   sessionStorage.setItem('list', JSON.stringify(list))
    //   this.$router.push({
    //     path: '/diagnosis'
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
.box {
  min-height: 90vh;
  width: 98%;
  margin: 1%;
  padding: 1% 2%;
  background-color: #fff;
}
.innerBox {
  margin:  60px auto 0;
  width: 50%;
}
.el-button--primary {
    color: #FFF;
    background-color: #189F92;
    border-color: #189F92;
}
// margin-top: 100px;
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  .title {
    padding-left: 10px;
    border-left: 4px solid #189F92;
  }
  .addBtn {
    padding: 5px 20px;
    border: 1px solid #189F92;
    border-radius: 5px;
    color: #189F92;
    cursor: pointer;
  }
}
.btn {
  width: fit-content;
  margin: 20px auto;
  padding: 7px 50px;
  background-color: #189F92;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.selectBox {
  width: 100%;
  padding: 20px 100px;
}
.select {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.selectText {
  display: flex;
  align-items: center;
  margin-right: 100px;
}
</style>
